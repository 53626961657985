<template>
  <div>
    <div class="row topmainrow">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="topmainlayer card" :style="topmainHeight">
          <!-- <div class="title"><span class="title2">Andami –</span> <span class="title3">SHMS</span></div> -->
          <div class="row topmainbtns">
            <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 topcarddiv">
              <div class="maincard card" @click="goPage('/sop/swp/safeWorkPermit')">
                <div class="card-body border-color-green">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <div class="text-xs fw-bold text-white mb-1">안전작업허가</div>
                      <div class="h5">{{ data.workPermitCnt }} 건</div>
                    </div>
                    <div class="ms-2">
                      <span class="material-icons">handyman</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 topcarddiv">
              <div class="maincard card" @click="goPage('/sop/edu/result/educationResult')">
                <div class="card-body border-color-blue">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <div class="text-xs fw-bold text-white mb-1">교육</div>
                      <div class="h5">{{ data.eduCnt }} 건</div>
                    </div>
                    <div class="ms-2">
                      <span class="material-icons">school</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 topcarddiv">
              <div class="maincard card" @click="goPage('/sai/patrol/patrol')">
                <div class="card-body border-color-red">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <div class="text-xs fw-bold text-white mb-1">순회점검</div>
                      <div class="h5">{{ data.patrolCnt }} 건</div>
                    </div>
                    <div class="ms-2">
                      <span class="material-icons">engineering</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 topcarddiv">
              <div class="maincard card" @click="goPage('/sop/iim/near/nearAccident')">
                <div class="card-body border-color-yullow">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <div class="text-xs fw-bold text-white mb-1">아차사고</div>
                      <div class="h5">{{ data.nearAccCnt }} 건</div>
                    </div>
                    <div class="ms-2">
                      <span class="material-icons">warning</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mainbottomlayer">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="card cardcontents2">
          <div class="card-header">
            개선관리
            <div class="card-more" @click="goPage('/sop/ibm/impr')">more</div>
          </div>
          <div class="card-body no-padding-card">
            <q-table
              grid
              title=""
              :data="data.imprList"
              hide-header
              hide-bottom
              :rows-per-page-options="[0]"
              virtual-scroll
            >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="getDetailPage('IMPROVE',props.row)">
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', getColorStatus(props.row.ibmStepCd)]">
                    {{ props.row.ibmStepName }}
                  </div>
                  <div :class="['text-grid-etc', getColorStatus2(props.row.ibmTaskTypeCd)]">
                    {{ props.row.ibmTaskTypeName }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.ibmTitle }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="txt-box-grid text-gray-box">
                    {{ props.row.requestDeptName }}
                    <span>
                      <q-badge v-if="props.row.completeDateOver > 0" color="negative" label="지연" />
                    </span>
                  </div>
                  <div class="text-grid-etc" :style="props.row.approvalStatusCd === 'ASC9999999' ? 'text-decoration: line-through' : ''">
                    ~ {{ props.row.actionCompleteRequestDate }}
                    <q-badge v-if="props.row.completeDateOver > 0
                      && props.row.ibmStepCd !== 'IS00000001' && props.row.approvalStatusCd !== 'ASC9999999'" color="negative" label="지연" class="blinking" />
                  </div>
                  <div v-if="props.row.approvalStatusCd === 'ASC9999999'">👉 ~ {{props.row.targetDate}}</div>
                </q-card-section>
              </q-card>
            </div>
          </template>
            </q-table>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="data.imprList.length === 0">
              <q-card class="mobileTableCardLayer">
                <q-card-section horizontal class="text-center">
                  <q-card-section class="q-pt-xs">
                    <div class="text-grid-title text-grid-titlemain q-mt-sm q-mb-xs">등록된 개선관리가 없습니다.</div>
                  </q-card-section>
                </q-card-section>
              </q-card>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="card cardcontents">
          <div class="card-header">
            일상점검결과
            <div class="card-more" @click="goPage('/sop/mim/inspection/equipmentDailyInspection')">more</div>
          </div>
          <div class="card-body">
            <q-table
              grid
              title=""
              :data="data.dailyList"
              hide-header
              hide-bottom
              :rows-per-page-options="[0]"
              virtual-scroll
            >
              <template v-slot:item="props">
                <div class="col-12">
                  //주석<q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)"></q-card>
                  <q-card class="mobileTableCardLayer" @click="getDetailPage('DAILY', props.row)">
                    <q-card-section horizontal class="bg-grey-2">
                      <q-card-section class="q-pt-xs">
                        <div class="text-grid-title text-grid-titlemain q-mt-sm q-mb-xs">{{ props.row.dailyName }}</div>
                      </q-card-section>
                    </q-card-section>
                    <q-card-section class="grid-card-etc">
                        <div class="text-grid-etc-title">
                          점검부서 :
                        </div>
                        <div class="text-grid-etc">
                          {{ props.row.checkDeptName }}
                        </div>
                        <div :class="['text-grid-etc', getColorStatus2(props.row.checkStatusCd)]">
                          {{ props.row.checkStatusName }}
                        </div>
                        <div :class="['text-grid-etc', getColorStatus3(props.row.approvalStatusCd)]">
                          {{ props.row.approvalStatusName }}
                        </div>
                    </q-card-section>
                  </q-card>
                </div>
              </template>
            </q-table>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="data.dailyList.length === 0">
              <q-card class="mobileTableCardLayer">
                <q-card-section horizontal class="bg-grey-2 text-center">
                  <q-card-section class="q-pt-xs">
                    <div class="text-grid-title text-grid-titlemain q-mt-sm q-mb-xs">등록된 일상점검이 없습니다.</div>
                  </q-card-section>
                </q-card-section>
              </q-card>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="card cardcontents">
          <div class="card-header">
            열화관리점검결과
            <div class="card-more" @click="goPage('/sop/mim/inspection/equipmentDeteriorInspection')">more</div>
          </div>
          <div class="card-body">
            <q-table
              grid
              title=""
              :data="data.deteriorList"
              hide-header
              hide-bottom
              :rows-per-page-options="[0]"
              virtual-scroll
            >
              <template v-slot:item="props">
                <div class="col-12">
                  <q-card class="mobileTableCardLayer" @click="getDetailPage('DETERIOR', props.row)">
                    <q-card-section horizontal class="bg-grey-2">
                      <q-card-section class="q-pt-xs">
                        <div class="text-grid-title text-grid-titlemain q-mt-sm q-mb-xs"> {{ props.row.deteriorName }}</div>
                      </q-card-section>
                    </q-card-section>
                    <q-card-section class="grid-card-etc">
                      <div class="text-grid-etc-title">
                        라인 : 
                      </div>
                      <div class="text-grid-etc">
                        {{ props.row.lineCd }}
                      </div>
                      <div class="text-grid-etc-title">
                        진행단계 :
                      </div>
                      <div :class="['text-grid-etc', getColorStatus2(props.row.checkStatusCd)]">
                        {{ props.row.checkStatusName }}
                      </div>
                    </q-card-section>
                  </q-card>
                </div>
              </template>
            </q-table>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="data.deteriorList.length === 0">
              <q-card class="mobileTableCardLayer">
                <q-card-section horizontal class="bg-grey-2 text-center">
                  <q-card-section class="q-pt-xs">
                    <div class="text-grid-title text-grid-titlemain q-mt-sm q-mb-xs">등록된 열화관리점검 없습니다.</div>
                  </q-card-section>
                </q-card-section>
              </q-card>
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="card cardcontents2">
          <div class="card-header">
            공지사항
          </div>
          <div class="card-body no-padding-card">
            <q-table
              grid
              title=""
              :data="data.noticeList"
              hide-header
              hide-bottom
              :rows-per-page-options="[0]"
              virtual-scroll
            >
              <template v-slot:item="props">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                  <q-card class="mobileTableCardLayer" @click="getDetailPage('NOTICE',props.row)">
                    <q-card-section class="grid-card-etc">
                      <div :class="['text-grid-etc', getColorStatus3(props.row.noticeClassCd)]">
                        {{ props.row.noticeClassName }}
                      </div>
                    </q-card-section>
                    <q-card-section>
                      <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.noticeTitle }}</div>
                    </q-card-section>
                    <q-card-section class="grid-card-etc">
                      <div class="text-grid-etc">
                        {{ props.row.regDtStr }}
                      </div>
                    </q-card-section>
                  </q-card>
                </div>
              </template>
            </q-table>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="data.noticeList.length === 0">
              <q-card class="mobileTableCardLayer">
                <q-card-section horizontal class="text-center">
                  <q-card-section class="q-pt-xs">
                    <div class="text-grid-title text-grid-titlemain q-mt-sm q-mb-xs">등록된 공지사항이 없습니다.</div>
                  </q-card-section>
                </q-card-section>
              </q-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import mobileConfig from '@/js/mobileConfig';
export default {
  name: 'main-dashboard',
  props: {
  },
  data() {
    return {
      topmainHeight: 'height: 235px;',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      data: {
        workPermitCnt: '',
        eduCnt: '',
        patrolCnt: '',
        nearAccCnt: '',

        imprList: [],
        dailyList: [],
        deteriorList: [],
        noticeList: [],
      },
      grid: {
        data: [],
      },
      grid2: {
        data: [],
      },
      listUrl: '',
      startDate: '',
      endDate: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
    this.$nextTick(function() {
      window.addEventListener("resize", this.getWindowWidth);

      //Init
      this.getWindowWidth();
    });
  },
  beforeDestroy() {
  },
  methods: {
    init() {

      this.editable = this.$route.meta.editable;
      this.listUrl = mobileConfig.main.list.url;

      this.startDate = this.$comm.getCalculatedDate(
        this.$comm.getToday(),
        '-6m',
        'YYYY-MM-DD',
        'YYYY-MM-DD',
      );
      this.endDate = this.$comm.getCalculatedDate(
        this.$comm.getToday(),
        '6m',
        'YYYY-MM-DD',
        'YYYY-MM-DD',
      )
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        userId: this.$store.getters.user.userId,
        plantCd: this.$store.getters.user.plantCd,
        deptCd: this.$store.getters.user.deptCd,
        startDate: this.startDate,
        endDate: this.endDate,
        month: this.$comm.getThisMonth(),
      };
      this.$http.request((_result) => {
        this.data = _result.data
      },);
    },
    getDetailPage(type, key) {
      if (type === 'IMPROVE') {
        this.popupOptions.title = '개선 상세'; // 개선 상세
        this.popupOptions.param = {
          sopImprovementId: key ? key.sopImprovementId : '',
          ibmTaskTypeCd: key ? key.ibmTaskTypeCd : 'ITT9999999',
        };
        this.popupOptions.target = () => import(`${'@/pages/ibm/imprDetail.vue'}`);
      } else if (type === 'DAILY') {
        this.popupOptions.title = key.dailyName  + ' 상세'; // 일상점검
        this.popupOptions.param = {
          dailyCheckId: key ? key.dailyCheckId : '',
        };
        this.popupOptions.target = () => import(`${'@/pages/pc/equipmentDailyDetail.vue'}`);
      } else if (type === 'NOTICE') {
        this.popupOptions.title = '공지사항 상세'; // 공지사항 상세
        this.popupOptions.param = {
          sysNoticeId: key ? key.sysNoticeId : '',
        };
        this.popupOptions.target = () => import(`${'./noticePop.vue'}`);
      }
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;

      if (this.windowWidth > "1024") {
        this.topmainHeight = 'height:135px;';
      } else {
        this.topmainHeight = 'height:235px;';
      }
    },
    goPage(_page) {
      this.$comm.movePage(this.$router, _page)
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'IS00000001': // 요청중
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'IS00000005': // 조치부서 접수중
          cls = 'txt-box-grid text-positive-box';
          break;
        case 'IS00000010': // 조치부서 조치중
          cls = 'txt-box-grid text-accent-box';
          break;
        case 'IS00000015': // 요청부서 검토중
          cls = 'txt-box-grid text-red-box';
          break;
        case 'IS00000020': // 요청부서 검토중
          cls = 'txt-box-grid text-orange-box';
          break;
      }
      return cls;
    },
    getColorStatus2() {
      return 'txt-box-grid text-gray-box';
    },
    getColorStatus3(cd) {
      var cls = '';
      switch(cd) {
        case 'NCC0000001': // 일반
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'NCC0000002': // 긴급
          cls = 'txt-box-grid text-red-box';
          break;
      }
      return cls;
    },
  }
};
</script>
<style lang="sass">
  .topmainlayer
    box-shadow: 0 0 0 0
    border-width: 0px !important
    border-radius: 0px !important
    background-color: #25476a
    display: block
    div.title
      padding: 15px 0px 0px 20px
      font-weight: 600
      color: #fff
      font-size: 20px
    .title1
      color: #fff
      font-size: 25px
    .title2
      color: #fff
      font-size: 35px
    .title3
      color: #fff
      font-size: 35px
    .today
      color: #fb8c00
      padding-top: 35px
      padding-left: 23px
      font-weight: 600
  .mainchild
    margin-top: -225px !important
    padding: 5px 5px 5px 5px
  .maincard
    margin-bottom: 1px
    min-height: 90px
    background-color: transparent !important
  .maincard .card-body
    background: #fff
    border-radius: 5px
    padding: 15px !important
    .fw-bold
      font-weight: 600
    .material-icons
      font-size: 3em
      color: #fff
      cursor: pointer
  .maincard .card-body.border-color-blue
    background: #0061f2
    color: #fff
  .maincard .card-body.border-color-red
    background: #d92550
    color: #fff
  .maincard .card-body.border-color-yullow
    background: #f7b924
    color: #fff
  .maincard .card-body.border-color-green
    background: #3ac47d
    color: #fff
  .topmainbtns
    padding-top: 10px
    width: 95%
    margin: 0 auto !important
    .topcarddiv
      padding:5px !important
  .mainbottomlayer
    width: 95%
    margin: 0 auto !important
    margin-top: -20px !important
  .text-grid-titlemain
    font-size: 1.05em !important
</style>
<style scoped>
.widget-numbers {
  font-size: 20px;
}
.impr-item {
  height: 100% !important;
}
</style>